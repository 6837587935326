.__snackbar {
  z-index: 1000;
  &.__right {
    right: 0;
    .__snackbar_message {
      right: 0;
    }
  }
  &.__left {
    left: 0;
    .__snackbar_message {
      left: 0;
    }
  }
  &.__center {
    left: 50%;
    transform: translateX(-50%);
    .__snackbar_message {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .__snackbar_message {
    min-width: 280px;
    border-bottom-width: 3px;
    transition: all 0.2s linear;
    transform: translateY(100px);
  	animation: snackbarEnter 0.1s ease-in forwards;

    opacity: 0;
    &.success {
      border-bottom-color: #1EBF68;
      .__snackbar_message_icon {
        color: #1EBF68;
      }
    }
    &.info {
      border-bottom-color: #acacac;
      .__snackbar_message_icon {
        color: #acacac;
      }
    }
    &.warning {
      border-bottom-color: #FBAD46;
      .__snackbar_message_icon {
        color: #FBAD46;
      }
    }
    &.error {
      border-bottom-color: #FF4D4D;
      .__snackbar_message_icon {
        color: #FF4D4D;
      }
    }
  }
}


@keyframes :global(snackbarEnter) {
	0% {
		opacity: 0;
    transform: translateY(100px);
	}

	100% {
		opacity: 1;
    transform: translateY(0px);
	}
}
