.tabs {
	border-bottom: 1px solid $gray5;
	a, button {
		padding: .75rem;
		height: auto;
		font-weight: $baseFontWeight;
		position: relative;
		color: #80878E;
		font-size: 1.125rem;
		display: inline-block;
		margin-bottom: -1px;
		&:after {
			content: '';
			height: 3px;
			background: transparent;
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			transition: background .2s linear;
			width: 100%;
		}
		&.active-tab {
			font-weight: $boldFontWeight;
			color: $baseFontColor;
			&:after {
				background: $red;
			}
		}
		&:hover:not(.active-tab) {
			&:after {
				background: $gray3;
			}
		}
		&:focus, &:active, &:hover {
			outline: none;
		}
	}
}