@keyframes __spinnerAnimation {
  0% {
    transform: rotate(0deg)
  }
  30% {
  	transform: rotate(200deg);
  }
  100% {
    transform: rotate(360deg)
  }
}

.spinner {
  display: inline-block;
  height: 44px;
  width: 44px;
  border: 2px solid transparent;
  border-color: #ededed;
  border-right-color: red;
  border-radius: 50%;
  animation: __spinnerAnimation .8s linear infinite;
  &.spinner-small {
    width: 22px;
    height: 22px;
  }
}