  $red: #FB0F3B;
  $lightRed: #FC4164;
  $darkRed: #DA3856;

  $purple: #7A6BBD;
  $lightPurple: #9787DC;
  $darkPurple: #574A91;

  $green: #1ACA8A;

  $gray1: #383E43;
  $gray2: #545C63;
  $gray3: #92999F;
  $gray4: #C7CDCF;
  $gray5: #E0E3E4;
  $gray6: #F9F9F9;
  
  $baseFontColor: #424A50;
  $lightFontColor: #9E9DA3;

  $baseFontWeight: 400;
  $boldFontWeight: 600;

  $baseFontSize: 14px;