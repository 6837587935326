

$datepicker__background-color: #ffffff !default;
$datepicker__border-color:  #C7CDCF !default;
$datepicker__highlighted-color: $red !default;
$datepicker__muted-color: #ccc !default;
$datepicker__selected-color: $red !default;
$datepicker__text-color: $baseFontColor !default;
$datepicker__header-color: $baseFontColor !default;

$datepicker__border-radius: 3px !default;
$datepicker__day-margin: .166rem !default;
$datepicker__font-size: .875rem !default;
$datepicker__font-family: "Roboto";
$datepicker__item-size: 2.33rem !default;
$datepicker__margin: .4rem !default;
$datepicker__navigation-size: .45rem !default;
$datepicker__triangle-size: 0px !default;

.datepicker-inline {
  border: none;
  height: auto;
  padding: 0;
  font-family: inherit;

}

.react-datepicker-wrapper {
  display: inline-block;
  position: relative;
  &:before {
  	content: '\E916';
  	font-family: 'Material Icons';
  	position: absolute;
  	left: 0;
  	padding-left: .5rem;
  	z-index: 1;
  	top: .35rem;
  	font-size: 1.5rem;
  	display: flex;
  	align-items: center;
  }
  input {
  	padding-left: 2.5rem;
    font-family: 'Roboto';
    font-size: 1rem;
  }
}

.react-datepicker {
  font-family: $datepicker__font-family;
  font-size: $datepicker__font-size;
  background-color: #fff;
  color: $datepicker__text-color;
  border: 1px solid $datepicker__border-color;
  border-radius: $datepicker__border-radius;
  display: inline-block;
  position: relative;
  box-shadow: 0 4px 7px 0 rgba(84,92,99,0.23);
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 2;

  &[data-placement^="bottom"] {
    margin-top: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      @extend %triangle-arrow-up;
    }
  }

  &[data-placement^="top"] {
    margin-bottom: $datepicker__triangle-size + 2px;

    .react-datepicker__triangle {
      @extend %triangle-arrow-down;
    }
  }

  &[data-placement^="right"] {
    margin-left: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: auto;
      right: 42px;
    }
  }

  &[data-placement^="left"] {
    margin-right: $datepicker__triangle-size;

    .react-datepicker__triangle {
      left: 42px;
      right: auto;
    }
  }
}

.react-datepicker__header {
  text-align: center;
  background-color: $datepicker__background-color;
  border-top-left-radius: $datepicker__border-radius;
  border-top-right-radius: $datepicker__border-radius;
  position: relative;

  &--time {
    padding-bottom: 8px;
  }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__current-month {
	margin: .875rem 0 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header {
  margin-top: 0;
  color: $datepicker__header-color;
  font-weight: bold;
  font-size: $datepicker__font-size * 1.18;
}

.react-datepicker__navigation {
  position: absolute;
  top: 12px;
  z-index: 1;
  font-size: 1.25rem;
  cursor: pointer;
  &--previous {
  	&:after {
  		content: '\E314';
  		font-family: 'Material Icons';
  	}
    left: 10px;
  }

  &--next {
  	&:after {
  		content: '\E315';
  		font-family: 'Material Icons';
  	}
    // &--with-time:not(&--with-today-button) {
    //   right: 80px;
    // }
    right: 10px;
  }

  // &--years {
  //   position: relative;
  //   top: 0;
  //   display: block;
  //   margin-left: auto;
  //   margin-right: auto;

  //   &-previous {
  //     top: 4px;
  //     border-top-color: $datepicker__muted-color;

  //     &:hover {
  //       border-top-color: darken($datepicker__muted-color, 10%);
  //     }
  //   }

  //   &-upcoming {
  //     top: -4px;
  //     border-bottom-color: $datepicker__muted-color;

  //     &:hover {
  //       border-bottom-color: darken($datepicker__muted-color, 10%);
  //     }
  //   }
  // }
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid $datepicker__border-color;

  &--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    position: absolute;
    right: -72px;
    top: 0;
  }

  .react-datepicker__time {
    position: relative;
    background: white;

    .react-datepicker__time-box {
      width: 70px;
      overflow-x: hidden;
      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (#{$datepicker__item-size} / 2));
        overflow-y: scroll;
        padding-right: 30px;
        width: 100%;

        li.react-datepicker__time-list-item {
          padding: 5px 10px;
          &:hover {
            cursor: pointer;
            background-color: $datepicker__background-color;
          }
          &--selected {
            background-color: $datepicker__selected-color;
            color: white;
            font-weight: bold;
            &:hover {
              background-color: $datepicker__selected-color;
            }
          }
          &--disabled {
            color: $datepicker__muted-color;

            &:hover {
              cursor: default;
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}

.react-datepicker__week-number {
  color: $datepicker__muted-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
  &.react-datepicker__week-number--clickable {
    cursor: pointer;
    &:hover {
      border-radius: $datepicker__border-radius;
      background-color: $datepicker__background-color;
    }
  }
}

.react-datepicker__day-names {
	font-size: 0.66rem;
	border-top: 1px solid $datepicker__border-color;
	padding: .5rem 0 0 0;
}
.react-datepicker__day-names, .react-datepicker__week{
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day, .react-datepicker__time-name {
  color: $datepicker__text-color;
  display: inline-block;
  width: $datepicker__item-size;
  line-height: $datepicker__item-size;
  text-align: center;
  margin: $datepicker__day-margin;
}

.react-datepicker__day {
  cursor: pointer;

  &:hover {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__background-color;
  }

  &--highlighted {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__highlighted-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__highlighted-color, 5%);
    }

    &-custom-1 {
      color: magenta;
    }

    &-custom-2 {
      color: green;
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $datepicker__border-radius;
    background-color: $datepicker__selected-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--keyboard-selected {
    border-radius: 50%;
    background-color: $datepicker__selected-color;
    color: #fff;

    &:hover {
      background-color: darken($datepicker__selected-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($datepicker__selected-color, .5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $datepicker__background-color;
      color: $datepicker__text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $datepicker__muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  border: 1px solid transparent;
  border-radius: $datepicker__border-radius;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %triangle-arrow-down;
    border-top-color: $datepicker__muted-color;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: $datepicker__navigation-size;
  }

}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: $datepicker__background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: $datepicker__border-radius;
  border: 1px solid $datepicker__border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $datepicker__border-radius;
    border-top-right-radius: $datepicker__border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $datepicker__border-radius;
    border-bottom-right-radius: $datepicker__border-radius;
  }

  &:hover {
    background-color: $datepicker__muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($datepicker__muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($datepicker__muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;

  &::after {
    background-color: $datepicker__selected-color;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
  }
}

.react-datepicker__today-button {
  background: $datepicker__background-color;
  border-top: 1px solid $datepicker__border-color;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day, .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }

  // Resize for small screens
  @media (max-width: 400px), (max-height: 550px)  {
    .react-datepicker__day-name,
    .react-datepicker__day, .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }

  .react-datepicker__current-month, .react-datepicker-time__header {
    font-size: 1.25rem;
  }

  .react-datepicker__navigation {
    border: 1.8 * $datepicker__navigation-size solid transparent;
    top: 3px;
  }

  // .react-datepicker__navigation--previous {
  //   border-right-color: $datepicker__muted-color;

  //   &:hover {
  //     border-right-color: darken($datepicker__muted-color, 10%);
  //   }
  // }

  // .react-datepicker__navigation--next {
  //   border-left-color: $datepicker__muted-color;

  //   &:hover {
  //     border-left-color: darken($datepicker__muted-color, 10%);
  //   }
  // }
}



%triangle-arrow {
  margin-left: -$datepicker__triangle-size;
  position: absolute;

  &,
  &::before {
    box-sizing: content-box;
    position: absolute;
    border: $datepicker__triangle-size solid transparent;

    height: 0;
    width: 1px;
  }

  &::before {
    content: "";
    z-index: -1;
    border-width: $datepicker__triangle-size;

    left: -$datepicker__triangle-size;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$datepicker__triangle-size;

  &,
  &::before {
    border-top: none;
    border-bottom-color: $datepicker__background-color;
  }

  &::before {
    top: -1px;
    border-bottom-color: $datepicker__border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::before {
    bottom: -1px;
    border-top-color: $datepicker__border-color;
  }
}
