.opacity-0 { opacity: 0 }

.opacity-5 { opacity: 0.5 }

.opacity-1 { opacity: 1 }

.scale-0 { transform: scale(0) }

.pointer { cursor: pointer }

.not-allowed { cursor: not-allowed }

.select-none {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}