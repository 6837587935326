
/* Basscss Position */

.relative { position: relative }

.absolute { position: absolute }

.fixed    { position: fixed }

.top-0    { top: 0 }

.right-0  { right: 0 }

.bottom-0 { bottom: 0 }

.left-0   { left: 0 }

.top-100 { top: 100% }

.right-100  { right: 100% }

.bottom-100 { bottom: 100% }

.left-100   { left: 100% }

.z1 { z-index: 1 }

.z2 { z-index: 2 }

.z3 { z-index: 3 }

.z4 { z-index: 4 }


/* Basscss Responsive Position */

@media (min-width: 40em) {
  .sm-relative { position: relative }
  .sm-absolute { position: absolute }
  .sm-fixed    { position: fixed }

  .sm-top-0    { top: 0 }
  .sm-right-0  { right: 0 }
  .sm-bottom-0 { bottom: 0 }
  .sm-left-0   { left: 0 }
}

@media (min-width: 52em) {
  .md-relative { position: relative }
  .md-absolute { position: absolute }
  .md-fixed    { position: fixed }

  .md-top-0    { top: 0 }
  .md-right-0  { right: 0 }
  .md-bottom-0 { bottom: 0 }
  .md-left-0   { left: 0 }
}

@media (min-width: 64em) {
  .lg-relative { position: relative }
  .lg-absolute { position: absolute }
  .lg-fixed    { position: fixed }

  .lg-top-0    { top: 0 }
  .lg-right-0  { right: 0 }
  .lg-bottom-0 { bottom: 0 }
  .lg-left-0   { left: 0 }
}

/* Basscss Responsive Typography */

@media (min-width: 40em) {
  .sm-left-align   { text-align: left }
  .sm-center       { text-align: center }
  .sm-right-align  { text-align: right }
  .sm-justify      { text-align: justify }
}

@media (min-width: 52em) {
  .md-left-align   { text-align: left }
  .md-center       { text-align: center }
  .md-right-align  { text-align: right }
  .md-justify      { text-align: justify }
}

@media (min-width: 64em) {
  .lg-left-align   { text-align: left }
  .lg-center       { text-align: center }
  .lg-right-align  { text-align: right }
  .lg-justify      { text-align: justify }
}