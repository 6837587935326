@import 'sass/variables';

@import 'sass/backgrounds';
@import 'sass/borders';
@import 'sass/buttons';
@import 'sass/flex';
@import 'sass/forms';
@import 'sass/layout';
@import 'sass/grid';
@import 'sass/margins';
@import 'sass/padding';
@import 'sass/progress';
@import 'sass/text';
@import 'sass/position';
@import 'sass/images';
@import 'sass/tabs';
@import 'sass/visual';
@import 'sass/tables';
@import 'sass/datepicker';
@import 'sass/select';
@import 'sass/spinner';
@import 'sass/aspera';
@import 'sass/snackbar';
@import 'sass/dialog';

* {
  box-sizing: border-box;
}


body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica, sans-serif;
  line-height: 1.643em;
  letter-spacing: 0em;
  color: $baseFontColor;
  font-weight: $baseFontWeight;
  font-size: $baseFontSize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: $gray6;
}


html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html:not(.wf-materialicons-n4-active) {
	.material-icons {
		font-size: 0px !important;
	}
}
