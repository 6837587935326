h1, h2, h3,
h4, h5, h6 {
  font-weight: $boldFontWeight;
  letter-spacing: -0.013em; 
  line-height: 1.4285em; 
  margin: 0;
  &.regular {
    font-weight: $baseFontWeight;
  }
}

h1 { 
  font-size: 2.857em;
}

h2 { 
  font-size: 2.286em; 
}

h3 {
  font-size: 1.571em; 
}

h4 {
  font-size: 1.286em; 
}

b, strong, .bold {
  font-weight: $boldFontWeight;
}

p, dl, ol, ul, pre, blockquote {
  margin: 0;
}

p {
   margin-bottom: 0.7143em; 
}
.monospace {
  font-family: monospace;
}
a {
  color: $baseFontColor;
  text-decoration: none;
  &:focus, &:hover, &:visited, &:active {
    outline: none;
    color: $baseFontColor;
    text-decoration: none;
  }
}

.caption {
  font-size: 0.857em; 
  line-height: 1.429em; 
  letter-spacing: 0.036em; 
}

small, .small {
  font-size: 0.714em; 
  line-height: 1.143em; 
  letter-spacing: 0.036em; 
}


/* Basscss Colors */
.light { color: $lightFontColor }

.white   { color: #ffffff }

.muted { opacity: .5 }

.red { color: $red }

.purple { color: $purple }

.green { color: $green }

/* Basscss Typography */

.font-family-inherit { font-family: inherit }

.font-size-inherit { font-size: inherit }

.text-decoration-none { text-decoration: none }

.regular { font-weight: $baseFontWeight }

.italic  { font-style: italic }

.caps    { text-transform: uppercase; letter-spacing: .2em; }

.capitalize { text-transform: capitalize }

.left-align   { text-align: left }

.center       { text-align: center }

.right-align  { text-align: right }

.justify      { text-align: justify }

.nowrap { white-space: nowrap }

.break-word { word-wrap: break-word }

.list-style-none { list-style: none }

.underline { text-decoration: underline }

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-reset {
  list-style: none;
  padding-left: 0;
}