/* Basscss Background Colors */

.bg-red  { background-color: $red }

.bg-light-red  { background-color: $lightRed }

.bg-dark-red { background-color: $darkRed }

.bg-white   { background-color: #ffffff }

.bg-purple { background-color: $purple }
	
.bg-light-purple  { background-color: $lightPurple }

.bg-dark-purple { background-color: $darkPurple }

.bg-gray-1 { background-color: $gray1 }

.bg-gray-2 { background-color: $gray2 }

.bg-gray-3 { background-color: $gray3 }

.bg-gray-4 { background-color: $gray4 }

.bg-gray-5 { background-color: $gray5 }

.bg-gray-6 { background-color: $gray6 }

.bg-green { background-color: $green }

/* Basscss Darken */

.bg-darken-1 { background-color: rgba(0, 0, 0, .0625) }

.bg-darken-2 { background-color: rgba(0, 0, 0, .125) }

.bg-darken-3 { background-color: rgba(0, 0, 0, .25) }

.bg-darken-4 { background-color: rgba(0, 0, 0, .5) }

.bg-darken-5 { background-color: rgba(0, 0, 0, .75) }

/* Basscss Lighten */

.bg-lighten-1 { background-color: rgba(255, 255, 255, .0625) }

.bg-lighten-2 { background-color: rgba(255, 255, 255, .125) }

.bg-lighten-3 { background-color: rgba(255, 255, 255, .25) }

.bg-lighten-4 { background-color: rgba(255, 255, 255, .5) }

/* Basscss Background Images */

.bg-cover   { background-size: cover }

.bg-contain { background-size: contain }

.bg-center  { background-position: center }

.bg-top     { background-position: top }

.bg-right   { background-position: right }

.bg-bottom  { background-position: bottom }

.bg-left    { background-position: left }

.bg-no-repeat { background-repeat: no-repeat }

.bg-repeat-x { background-repeat: repeat-x }

.bg-repeat-y { background-repeat: repeat-y }