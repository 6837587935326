.flex { display: -webkit-box; display: -ms-flexbox; display: flex }

.inline-flex { display: -ms-inline-flexbox; display: inline-flex; }

.flex-column  { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column }

.flex-wrap    { -ms-flex-wrap: wrap; flex-wrap: wrap }

.items-start    { -webkit-box-align: start; -ms-flex-align: start; -ms-grid-row-align: flex-start; align-items: flex-start }

.items-end      { -webkit-box-align: end; -ms-flex-align: end; -ms-grid-row-align: flex-end; align-items: flex-end }

.items-center   { -webkit-box-align: center; -ms-flex-align: center; -ms-grid-row-align: center; align-items: center }

.items-baseline { -webkit-box-align: baseline; -ms-flex-align: baseline; -ms-grid-row-align: baseline; align-items: baseline }

.items-stretch  { -webkit-box-align: stretch; -ms-flex-align: stretch; -ms-grid-row-align: stretch; align-items: stretch }

.self-start    { -ms-flex-item-align: start; align-self: flex-start }

.self-end      { -ms-flex-item-align: end; align-self: flex-end }

.self-center   { -ms-flex-item-align: center; align-self: center }

.self-baseline { -ms-flex-item-align: baseline; align-self: baseline }

.self-stretch  { -ms-flex-item-align: stretch; align-self: stretch }

.justify-start   { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start }

.justify-end     { -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end }

.justify-center  { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center }

.justify-between { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between }

.justify-around  { -ms-flex-pack: distribute; justify-content: space-around }

.content-start   { -ms-flex-line-pack: start; align-content: flex-start }

.content-end     { -ms-flex-line-pack: end; align-content: flex-end }

.content-center  { -ms-flex-line-pack: center; align-content: center }

.content-between { -ms-flex-line-pack: justify; align-content: space-between }

.content-around  { -ms-flex-line-pack: distribute; align-content: space-around }

.content-stretch { -ms-flex-line-pack: stretch; align-content: stretch }

/* 1. Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */

.flex-auto {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-width: 0; /* 1 */
  min-height: 0; /* 1 */
}

.flex-none { -webkit-box-flex: 0; -ms-flex: none; flex: none }

.order-0 { -webkit-box-ordinal-group: 1; -ms-flex-order: 0; order: 0 }

.order-1 { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1 }

.order-2 { -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2 }

.order-3 { -webkit-box-ordinal-group: 4; -ms-flex-order: 3; order: 3 }

.order-last { -webkit-box-ordinal-group: 100000; -ms-flex-order: 99999; order: 99999 }