.max-width-1 { max-width: 24rem }

.max-width-2 { max-width: 32rem }

.max-width-3 { max-width: 48rem }

.max-width-4 { max-width: 64rem }

.full-height { height: 100vh }

.half-height { height: 50vh }

/* Basscss Grid */

.col {
  float: left;
  box-sizing: border-box;
}

.col-right {
  float: right;
  box-sizing: border-box;
}

.col-1 {
  width: 8.33333%;
}

.col-2 {
  width: 16.66667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33333%;
}

.col-5 {
  width: 41.66667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33333%;
}

.col-8 {
  width: 66.66667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33333%;
}

.col-11 {
  width: 91.66667%;
}

.col-12 {
  width: 100%;
}

@media (min-width: 40em) {

  .sm-col {
    float: left;
    box-sizing: border-box;
  }

  .sm-col-right {
    float: right;
    box-sizing: border-box;
  }

  .sm-col-1 {
    width: 8.33333%;
  }

  .sm-col-2 {
    width: 16.66667%;
  }

  .sm-col-3 {
    width: 25%;
  }

  .sm-col-4 {
    width: 33.33333%;
  }

  .sm-col-5 {
    width: 41.66667%;
  }

  .sm-col-6 {
    width: 50%;
  }

  .sm-col-7 {
    width: 58.33333%;
  }

  .sm-col-8 {
    width: 66.66667%;
  }

  .sm-col-9 {
    width: 75%;
  }

  .sm-col-10 {
    width: 83.33333%;
  }

  .sm-col-11 {
    width: 91.66667%;
  }

  .sm-col-12 {
    width: 100%;
  }

}

@media (min-width: 52em) {

  .md-col {
    float: left;
    box-sizing: border-box;
  }

  .md-col-right {
    float: right;
    box-sizing: border-box;
  }

  .md-col-1 {
    width: 8.33333%;
  }

  .md-col-2 {
    width: 16.66667%;
  }

  .md-col-3 {
    width: 25%;
  }

  .md-col-4 {
    width: 33.33333%;
  }

  .md-col-5 {
    width: 41.66667%;
  }

  .md-col-6 {
    width: 50%;
  }

  .md-col-7 {
    width: 58.33333%;
  }

  .md-col-8 {
    width: 66.66667%;
  }

  .md-col-9 {
    width: 75%;
  }

  .md-col-10 {
    width: 83.33333%;
  }

  .md-col-11 {
    width: 91.66667%;
  }

  .md-col-12 {
    width: 100%;
  }

}

@media (min-width: 64em) {

  .lg-col {
    float: left;
    box-sizing: border-box;
  }

  .lg-col-right {
    float: right;
    box-sizing: border-box;
  }

  .lg-col-1 {
    width: 8.33333%;
  }

  .lg-col-2 {
    width: 16.66667%;
  }

  .lg-col-3 {
    width: 25%;
  }

  .lg-col-4 {
    width: 33.33333%;
  }

  .lg-col-5 {
    width: 41.66667%;
  }

  .lg-col-6 {
    width: 50%;
  }

  .lg-col-7 {
    width: 58.33333%;
  }

  .lg-col-8 {
    width: 66.66667%;
  }

  .lg-col-9 {
    width: 75%;
  }

  .lg-col-10 {
    width: 83.33333%;
  }

  .lg-col-11 {
    width: 91.66667%;
  }

  .lg-col-12 {
    width: 100%;
  }

}

@media (min-width: 86em) {

  .xl-col {
    float: left;
    box-sizing: border-box;
  }

  .xl-col-right {
    float: right;
    box-sizing: border-box;
  }

  .xl-col-1 {
    width: 8.33333%;
  }

  .xl-col-2 {
    width: 16.66667%;
  }

  .xl-col-3 {
    width: 25%;
  }

  .xl-col-4 {
    width: 33.33333%;
  }

  .xl-col-5 {
    width: 41.66667%;
  }

  .xl-col-6 {
    width: 50%;
  }

  .xl-col-7 {
    width: 58.33333%;
  }

  .xl-col-8 {
    width: 66.66667%;
  }

  .xl-col-9 {
    width: 75%;
  }

  .xl-col-10 {
    width: 83.33333%;
  }

  .xl-col-11 {
    width: 91.66667%;
  }

  .xl-col-12 {
    width: 100%;
  }

}