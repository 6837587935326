
.box-shadow { box-shadow: 0 0 9px 0 rgba(0,0,0,.13) }

.border-red  { border-color: $red }

.border-light-red  { border-color: $lightRed }

.border-dark-red { border-color: $darkRed }

.border-white   { border-color: #ffffff }

.border-purple { border-color: $purple }
  
.border-light-purple  { border-color: $lightPurple }

.border-dark-purple { border-color: $darkPurple }

.border-gray-1 { border-color: $gray1 }

.border-gray-2 { border-color: $gray2 }

.border-gray-3 { border-color: $gray3 }

.border-gray-4 { border-color: $gray4 }

.border-gray-5 { border-color: $gray5 }

.border-gray-6 { border-color: $gray6 }

.border-transparent { border-color: transparent }
/* Basscss Border */

.border {
  border-style: solid;
  border-width: 1px;
}

.border-top {
  border-top-style: solid;
  border-top-width: 1px;
}

.border-right {
  border-right-style: solid;
  border-right-width: 1px;
}

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.border-left {
  border-left-style: solid;
  border-left-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.border-width-2 { border-width: 2px }

.border-width-3 { border-width: 3px }

.border-width-4 { border-width: 4px }

.border-width-5 { border-width: 5px }

.border-none { border: 0 }

.rounded { border-radius: 3px }

.circle  { border-radius: 50% }

.rounded-top    { border-radius: 3px 3px 0 0 }

.rounded-right  { border-radius: 0 3px 3px 0 }

.rounded-bottom { border-radius: 0 0 3px 3px }

.rounded-left   { border-radius: 3px 0 0 3px }

.not-rounded { border-radius: 0 }