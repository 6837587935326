table {
	border-collapse: collapse;
	width: 100%;
	thead {
		&.dark {
			background: $gray6;
		}
		th {
			color: #aeadb2;
			font-size: 12px;
			font-weight: normal;
			border: none;
			border-bottom: 1px solid #d0d3d8;
			text-align: left;
			padding: 0.5rem 0.25rem;
			user-select: none;
			cursor: pointer;
			.material-icons {
				font-size: 12px;
				vertical-align: middle;
				margin-left: 0.25rem;
			}
		}
	}
	tbody {
		tr {
			&:hover {
				background-color: #f9f9f9;
			}
			td {
				border-bottom: 1px solid #e9ebee;
				padding: 1rem 0.25rem;
				font-size: 14px;
				button {
					height: auto;
				}
			}
		}
	}
}

.__table {
	.__body {
		.__body_row {
			&_tall {
				height: 60px;
				&.__body_row_clickable {
					&:hover:not(.__body_row_checked) {
						box-shadow: inset 0 -1px 0 0 #ebeced, 0 2px 7px 0 #c7cdcf;
					}
				}
			}
			&_compact {
				height: 40px;
				font-size: 12px;
			}
			box-shadow: inset 0 -1px 0 0 #ebeced,
				0 2px 7px 0 rgba(224, 227, 228, 0.62);
			&_clickable {
				&:hover {
					*:not(button):not(input):not(.material-icons) {
						font-weight: bold;
					}
					button * {
						font-weight: normal !important;
					}
					cursor: pointer;
				}
			}
			&_checked {
				&.__body_row_tall {
					background-color: #fffbfb;
					box-shadow: 0 0 1px 1px #e30a33;
				}
				&.__body_row_compact {
					background-color: #fffbfb;
				}
			}
			.__checkbox_row {
				min-width: 42px;
			}
		}
	}
	ul.cs-paginate {
		li {
			&.cs-break {
				display: block;
				background: linear-gradient(0deg, #f7f7f7 0%, #ffffff 100%);
				padding: 0 0.75rem;
				border-top: 1px solid #c7cdcf;
				border-bottom: 1px solid #c7cdcf;
				border-left: 1px solid #c7cdcf;

				a.a_cs-break {
					background: none;
					border: none;
					margin: 0;
					padding: 0;
				}
			}
			a {
				cursor: pointer;
				background: linear-gradient(0deg, #f7f7f7 0%, #ffffff 100%);
				display: block;
			}
			&.previous {
				margin-right: 1rem;
			}
			&.next {
				margin-left: 1rem;
			}
			&.selected {
				a {
					background: linear-gradient(0deg, #d9d9d9 0%, #f3f3f3 100%);
					font-weight: bold;
				}
			}
			&.next,
			&.previous {
				a {
					border-radius: 3px;
					border: 1px solid #c7cdcf;
					color: #424a50;
					font-weight: bold;
					padding: 0 0.5rem;
				}
			}
			&:not(.next),
			&:not(.previous) {
				a {
					padding: 0 1rem;
					border-top: 1px solid #c7cdcf;
					border-bottom: 1px solid #c7cdcf;
					border-left: 1px solid #c7cdcf;
				}
			}
			&:nth-child(2) {
				a {
					border-top-left-radius: 3px;
					border-bottom-left-radius: 3px;
				}
			}
			&:nth-last-child(2) {
				a {
					border-top-right-radius: 3px;
					border-bottom-right-radius: 3px;
					border-right: 1px solid #c7cdcf;
				}
			}
		}
	}
}
