.__dialog {
  width: 320px;
  transform: scaleX(0);
  animation: dialog 0.1s linear forwards;
  &.__dialog_left {
    transform-origin: left;
  }
  &.__dialog_right {
    transform-origin: right;
  }
}

@keyframes :global(dialog) {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}
