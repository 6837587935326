/* Basscss Layout */

.inline       { display: inline }

.block        { display: block }

.inline-block { display: inline-block }

.table        { display: table }

.table-cell   { display: table-cell }

.overflow-hidden { overflow: hidden }

.overflow-scroll { overflow: scroll }

.overflow-auto   { overflow: auto }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table
}

.clearfix:after { clear: both }

.left  { float: left }

.right { float: right }

.fit { max-width: 100% }


.border-box { box-sizing: border-box }

/* Basscss Hide */

.hide {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@media (max-width: 40em) {
  .xs-hide { display: none !important }
}

@media (min-width: 40em) and (max-width: 52em) {
  .sm-hide { display: none !important }
}

@media (min-width: 52em) and (max-width: 64em) {
  .md-hide { display: none !important }
}

@media (min-width: 64em) {
  .lg-hide { display: none !important }
}

.display-none { display: none !important }


/* Basscss Responsive Layout */

@media (min-width: 40em) {

  .sm-inline       { display: inline }
  .sm-block        { display: block }
  .sm-inline-block { display: inline-block }
  .sm-table        { display: table }
  .sm-table-cell   { display: table-cell }

  .sm-overflow-hidden { overflow: hidden }
  .sm-overflow-scroll { overflow: scroll }
  .sm-overflow-auto   { overflow: auto }

  .sm-left  { float: left }
  .sm-right { float: right }
  .sm-flex { display: -webkit-box; display: -ms-flexbox; display: flex }

}

@media (min-width: 52em) {

  .md-inline       { display: inline }
  .md-block        { display: block }
  .md-inline-block { display: inline-block }
  .md-table        { display: table }
  .md-table-cell   { display: table-cell }

  .md-overflow-hidden { overflow: hidden }
  .md-overflow-scroll { overflow: scroll }
  .md-overflow-auto   { overflow: auto }

  .md-left  { float: left }
  .md-right { float: right }
  .md-flex { display: -webkit-box; display: -ms-flexbox; display: flex }

}

@media (min-width: 64em) {

  .lg-inline       { display: inline }
  .lg-block        { display: block }
  .lg-inline-block { display: inline-block }
  .lg-table        { display: table }
  .lg-table-cell   { display: table-cell }

  .lg-overflow-hidden { overflow: hidden }
  .lg-overflow-scroll { overflow: scroll }
  .lg-overflow-auto   { overflow: auto }

  .lg-left  { float: left }
  .lg-right { float: right }
  .lg-flex { display: -webkit-box; display: -ms-flexbox; display: flex }

}

